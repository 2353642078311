export default function CardSeceleton() {
  return (
    <div
      className='shadow-normalGON flex h-full cursor-pointer flex-col justify-between gap-6 rounded-xl border border-transparent bg-greyscaleGON-50 p-2 focus:border-purple-400
    md:p-4 md:gap-8
    '>
      <div className='bg-greyscaleGON-200 h-[140px] lg:h-[300px] rounded-lg animate-pulse'></div>
      <div className='flex flex-col gap-2'>
        <div className='bg-greyscaleGON-200 h-10 rounded-lg animate-pulse'></div>
        <div className='bg-greyscaleGON-200 h-10 rounded-lg animate-pulse'></div>
      </div>
    </div>
  );
}
