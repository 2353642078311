"use client";

import { memo } from "react";
import ProductList from "@/components/atom/ProductList";
import Link from "next/link";

function ProductHits({ dataProducts = [] }) {
  function goToSearch() {
    const section = document.getElementById("btn-link-products-hits");
    if (section) section.click();
  }
  return (
    <section className={"container-mini flex flex-col gap-10"}>
      <ProductList
        datas={dataProducts}
        title={"Koleksi Terpopuler"}
        withoutPageSize
        // horizontal
        handleNextPage={{
          show: false,
        }}
        handleAction={{
          title: "Lihat Semua",
          onclick: () => goToSearch(),
        }}
      />
      <Link
        className="sr-only"
        id="btn-link-products-hits"
        href={`/products/hits`}
        as={`/products/hits`}
        aria-label={"hits"}
        shallow
        passHref
        rel="preload"
      />
    </section>
  );
}

export default memo(ProductHits);
