"use client";

import { memo } from "react";
import Image from "next/image";
import Link from "next/link";
import { BlurImage } from "@/public/images";
import { cn } from "@/lib/utils";
import Swiper from "@/components/atom/Swiper";

function Banner({ banner = [] }) {
  return (
    <section className={cn("z-10", banner?.length < 0 ? "mt-20" : "mt-[115px]")}>
      {banner?.length > 0 && (
        <Swiper
          items={banner?.length > 0 ? banner : []}
          // autoplay
          noEffect
          className='flex flex-col gap-2.5'
          styleSwiper={"aspect-[16/6] max-w-full"}
          rendeitem={({ item, index }) => {
            const url = item?.url;
            const callbackUrl = item?.link_url;
            return !url ? null : (
              <Link
                key={index}
                aria-label={`banner-${index}`}
                as={callbackUrl ?? "/"}
                shallow
                passHref
                target='_blank'
                href={callbackUrl ?? "/"}
                rel='preload'>
                <Image
                  src={url}
                  alt={`Carousel Image ${index + 1}`}
                  className='pointer-events-none w-screen h-auto object-cover object-top aspect-[16/6]'
                  width={100}
                  height={100}
                  sizes='100vw, 50vw, 33vw, 25vw'
                  placeholder='blur'
                  blurDataURL={BlurImage?.blurDataURL}
                  priority
                  quality={70}
                />
              </Link>
            );
          }}
        />
      )}
    </section>
  );
}

export default memo(Banner);
