"use client";

import { memo, } from "react";
import ProductList from "@/components/atom/ProductList";
import Link from "next/link";
import Button from "@/components/atom/Button";

function Product({ dataProducts = [] }) {
  function goToSearch() {
    const section = document.getElementById("btn-link-search");
    if (section) section.click();
  }

  return (
    <section className={"container-mini flex flex-col gap-10"}>
      <ProductList
        datas={dataProducts}
        title={"Koleksi Produk"}
        withoutPageSize
        handleAction={{
          title: "Lihat Semua",
          onclick: () => goToSearch(),
        }}
        handleNextPage={{
          show: false,
        }}
      />
      <div className="flex w-full justify-center items-center py-10">
        <Button
          className="min-w-[12px]"
          variants="outline"
          label={`Muat Lebih Banyak`}
          onClick={goToSearch}
        />
      </div>
      <Link
        className="sr-only"
        id="btn-link-search"
        href={`/search`}
        as={`/search`}
        aria-label={"search"}
        shallow
        passHref
        rel="preload"
      />
    </section>
  );
}

export default memo(Product);
