"use client";

import React, { memo, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import "swiper/css/thumbs";

import "@/styles/swiper-tailwind.css";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCreative,
  Thumbs,
  Grid,
} from "swiper/modules";
import { twMerge } from "tailwind-merge";
import { ChevronLeftSvg, ChevronRightSvg } from "@/public/svg";

const MySwiper = ({
  items = [],
  rendeitem = () => {},
  autoplay = true,
  navigation = false,
  loop = true,
  getActive,
  className,
  onSwiper,
  showThumb = false,
  onClick,
  styleContent,
  styleSwiper,
  slideTo = 0,
  noEffect = false,
  handleOnSlideChange = () => {},
}) => {
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activethumbsSwiper, setActiveThumbsSwiper] = useState(0);
  const RenderItem = rendeitem;

  useEffect(() => {
    swiper?.slideTo(slideTo, 0);
  }, [slideTo, swiper]);

  return (
    <div className={twMerge("flex flex-col gap-2.5", className)}>
      <div className={twMerge(styleContent)}>
        <Swiper
          // install Swiper modules
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Autoplay,
            EffectCreative,
            Thumbs,
          ]}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          onActiveIndexChange={getActive}
          spaceBetween={10}
          slidesPerView={1}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            enabled: navigation,
          }}
          autoplay={
            autoplay && {
              delay: 8000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
              reverseDirection: false,
            }
          }
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(val) => {
            onSwiper && onSwiper(val);
            setSwiper(val);
          }}
          grabCursor={true}
          loop={loop ?? true}
          effect={noEffect ? "" : "creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-120%", 0, -500],
            },
            next: {
              shadow: true,
              translate: ["120%", 0, -500],
            },
          }}
          onSlideChange={(props) => {
            handleOnSlideChange(props);
            setActiveThumbsSwiper(props?.activeIndex);
          }}
          className={twMerge(styleSwiper)}
          centeredSlides
          centerInsufficientSlides
          centeredSlidesBounds>
          {items.map((item, key) => (
            <SwiperSlide
              key={key}
              onClick={() => onClick && onClick(item, key)}>
              {/* Your slide content goes here */}
              <div className='flex w-full justify-center items-center self-center'>
                <RenderItem
                  index={key}
                  item={item}
                />
              </div>
            </SwiperSlide>
          ))}

          <div
            className={twMerge(!navigation && "hidden", "swiper-button-prev")}>
            <ChevronLeftSvg className='h-10 w-10 text-primaryGON-400' />
          </div>
          <div
            className={twMerge(!navigation && "hidden", "swiper-button-next")}>
            <ChevronRightSvg className='h-10 w-10 text-primaryGON-400' />
          </div>
        </Swiper>
      </div>
      {showThumb && (
        <div className={twMerge(styleContent)}>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{
              clickable: true,
            }}
            watchSlidesProgress={true}
            modules={[Navigation, Thumbs, Grid]}
            className='mySwiper'>
            {items.map((item, key) => {
              return (
                <SwiperSlide
                  key={key}
                  className={twMerge(
                    (activethumbsSwiper == key || slideTo == key) &&
                      "border border-primaryGON-400",
                    "overflow-hidden sm:h-[70px] sm:w-[70px] h-full w-full"
                  )}
                  onClickCapture={(props) => setActiveThumbsSwiper(key)}>
                  {/* Your slide content goes here */}
                  <RenderItem
                    index={key}
                    item={item}
                  />
                </SwiperSlide>
              );
            })}

            <div className='swiper-button-prev'>
              <ChevronLeftSvg className='h-10 w-10 text-primaryGON-400' />
            </div>
            <div className='swiper-button-next'>
              <ChevronRightSvg className='h-10 w-10 text-primaryGON-400' />
            </div>
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default memo(MySwiper);
