"use client";

import { memo } from "react";
import ProductList from "@/components/atom/ProductList";
import Link from "next/link";

function ProductNew({ dataProducts = [] }) {
  function goToSearch() {
    const section = document.getElementById("btn-link-products-news");
    if (section) section.click();
  }

  return (
    <section className={"container-mini flex flex-col gap-10"}>
      <ProductList
        datas={dataProducts}
        title={"Koleksi Terbaru"}
        withoutPageSize
        // horizontal
        handleNextPage={{
          show: false,
        }}
        handleAction={{
          title: "Lihat Semua",
          onclick: () => goToSearch(),
        }}
      />
      <Link
        className="sr-only"
        id="btn-link-products-news"
        href={`/products/news`}
        as={`/products/news`}
        aria-label={"news"}
        shallow
        passHref
        rel="preload"
      />
    </section>
  );
}

export default memo(ProductNew);
